import * as React from "react"
import {graphql, useStaticQuery} from "gatsby";

const Header = ({ siteTitle }) => {
    const data = useStaticQuery(graphql`query HeaderQuery {
      logo: file(relativePath: {eq: "logo.jpeg"}) {
        publicURL
      }
    }`);

    return (
        <header id="tW">
            <div className="bg">
                <div id="t" className="cent cfx">
                    <div id="logo">
                        <a href="/" title="Logo">
                            <img src={ data.logo.publicURL } alt={ siteTitle } className="hide" />
                        </a>
                    </div>
                    <a id="phone" href="tel:">tel</a>
                </div>
                <div id="mNav" className="cent cfx"></div>
            </div>
            <div id="sNavW">
                <div id="sNav" className="cent cfx sNavEx">
                    <ul>
                        <li id="sNav1">
                            <a href="/customer-service"><span></span>Customer Service</a>
                        </li>
                        <li id="sNav2">
                            <a href="/help-with-repayments">
                                <span></span>Help with repayments
                            </a>
                        </li>
                        <li id="sNav5">
                            <a href="/faq">
                                <span></span>Help &amp; FAQs
                            </a>
                        </li>
                    </ul>
                    <ul id="qLnks">
                        <li id="qL1">
                            <a href="/about-us"><span></span>About us</a>
                        </li>
                        <li id="qL2">
                            <a href="/contact-us"><span></span>Contact us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    )
}

export default Header
