import * as React from "react";
import {graphql, useStaticQuery} from "gatsby";

const Footer = () => {
    const data = useStaticQuery(graphql`query FooterQuery {
      footer: file(relativePath: {eq: "wealthwarning20032019.jpg"}) {
        publicURL
      }
    }`);

    return (
        <footer id="fW">
            <div id="f" className="cent cfx">
                <div className="disclaimer">
                    <img src={data.footer.publicURL} alt="" width="963" height="203" />
                </div>
                <div id="fTools" className="cfx">
                    <ul>
                        <li>
                            <a href="/sitemap">Sitemap</a>
                        </li>
                        <li>
                            <a href="/security-notice">Security Notice</a>
                        </li>
                        <li>
                            <a href="/our-fees">Our Fees</a>
                        </li>
                        <li>
                            <a href="/privacy-policy">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/website-terms-and-conditions">Website Terms and Conditions</a>
                        </li>
                        <li>
                            <a href="/accessibility">Accessibility</a>
                        </li>
                        <li>
                            <a href="/cookies">Cookies</a>
                        </li>
                        <li className="last">
                            <a href="/fla">FLA</a>
                        </li>
                    </ul>
                </div>
                <div id="fSmlPrnt" className="cfx">
                    <div className="col col1"></div>
                    <div className="col col2"></div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;